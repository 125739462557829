require('./landing.scss');
import {submitMailCta} from "../utils";
import Modal from "bootstrap/js/dist/modal";

$(() => {
    submitMailCta();

    if ($('#webinarModal').length) {
        const webinarModal = new Modal($('#webinarModal'));
        setTimeout(() => {
            webinarModal.show();
        }, 7000);
    }
});