function getCookie(cookieName) {
    const name = cookieName + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');

    for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) == ' ') {
            cookie = cookie.substring(1);
        }
        if (cookie.indexOf(name) == 0) {
            return cookie.substring(name.length, cookie.length);
        }
    }

    return "";
}

const submitMailCta = () => {
    const siteToken = $('#siteToken').data('token');
    const ctaSubmitButton = $('#submitEmailCtaFormButton')
    const redirectUrl = ctaSubmitButton.data('redirect-url');
    const emailCtaForm = $('.email-cta-hubspot');

    emailCtaForm.submit((e) => {
        e.preventDefault();
        grecaptcha.ready(function () {
            grecaptcha.execute(siteToken, {action: 'submit'}).then(async function (token) {
                const submittedForm = $(e.target);
                const actionUrl = submittedForm.attr('action');

                const nonce =  await vf.services.getOrFetchNonce()

                const additionalData = {
                    formId: submittedForm.data('form-id'),
                    portalId: submittedForm.data('portal-id'),
                    pageUri: window.location.href.toString(),
                    hubspotTrackingToken: getCookie('hubspotutk'),
                    token: token
                };

                const additionalDataArray = Object.keys(additionalData).map(key => ({
                    name: key,
                    value: additionalData[key]
                }));
                const formData = submittedForm.serializeArray().filter(item => !$(item).hasClass('collected-forms-workaround')).concat(additionalDataArray);
                $.ajax({
                    type: 'POST',
                    url: actionUrl,
                    data:
                        formData,
                    beforeSend: (xhr) => {
                        xhr.setRequestHeader('X-WP-Nonce', nonce);
                        ctaSubmitButton.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span class="visually-hidden">Loading...</span>');
                        ctaSubmitButton.prop('disabled', true);
                    }
                });
                window.location.href = redirectUrl;
            });
        });
        return false;
    });
};

const multiStepForm = (modalId, noSubmit = false) => {
    let currentStep = 1;
    const totalSteps = $("#multiStepForm [data-step]").length;
    const form = $('#multiStepForm');

    const getStepSelector = () => `#multiStepForm div[data-step='${currentStep}']`;
    const getStepInputSelector = () => `#multiStepForm div[data-step='${currentStep}'] input`;
    const getStepPointSelector = () => `${modalId} div[data-point-step='${currentStep}']`;

    const areAllCurrentStepInputsValid = () => {
        let inputElements = document.querySelectorAll(getStepInputSelector());
        let isValid = true;

        inputElements.forEach(inputElement => {
            if (!inputElement.checkValidity()) {
                isValid = false;
                inputElement.reportValidity();
            }
        });

        return isValid;
    }

    $('#multiStepForm input').on('input', (e) => {
        const target = $(e.target);

        if (target.hasClass('is-invalid') && target[0].checkValidity() && target.val()) {
            target.removeClass('is-invalid').addClass('is-valid');
        } else if (!target.val()) {
            target.removeClass('is-valid is-invalid');
        }
    })

    $('#multiStepForm input').on('blur', (e) => {
        const target = $(e.target);

        if (target.val()) {
            if (target[0].checkValidity()) {
                target.removeClass('is-invalid').addClass('is-valid');
            } else {
                target.removeClass('is-valid').addClass('is-invalid');
            }
        }
    })

    const handleNextButtonClick = () => {
        if (areAllCurrentStepInputsValid()) {
            $(getStepSelector()).hide();
            $(getStepPointSelector()).text('✓');
            currentStep++;
            $(getStepPointSelector()).removeClass('inactive').addClass('active');

            if (currentStep === totalSteps) {
                $('#multiStepForm .submit-btn').show();
                $('#multiStepForm .next-btn').hide();
            }
            $('#multiStepForm .prev-btn').show();

            $(getStepSelector()).removeClass('slide-in-left slide-in-right').addClass('slide-in-right').show();
        }
    };

    const handlePrevButtonClick = () => {
        $(getStepSelector()).hide();
        $(getStepPointSelector()).removeClass('active').addClass('inactive');
        currentStep--;
        $(getStepPointSelector()).text(currentStep);

        $('#multiStepForm .submit-btn').hide();
        $('#multiStepForm .next-btn').show();
        if (currentStep === 1) {
            $('#multiStepForm .prev-btn').hide();
        } else {
            $('#multiStepForm .prev-btn').show();
        }
        $(getStepSelector()).removeClass('slide-in-left slide-in-right').addClass('slide-in-left').show();
    };

    const handleFormSubmit = async (e) => {

        if (!noSubmit) {
            e.preventDefault();
            const nonce =  await vf.services.getOrFetchNonce()
            const actionUrl = form.attr('action');
            const additionalData = {
                formId: $(`${modalId}`).data('form-id'),
                portalId: $(`${modalId}`).data('portal-id'),
                pageUri: window.location.href.toString(),
                hubspotTrackingToken: getCookie('hubspotutk')
            };

            const additionalDataArray = Object.keys(additionalData).map(key => ({
                name: key,
                value: additionalData[key]
            }));
            const formData = form.serializeArray().filter(item => !$(item).hasClass('collected-forms-workaround')).concat(additionalDataArray);
            $.ajax({
                type: 'POST',
                url: actionUrl,
                data: formData,
                beforeSend: (xhr) => {
                    xhr.setRequestHeader('X-WP-Nonce', nonce);
                },
                success: (data) => {
                    $(getStepSelector()).hide();
                    $('#multiStepFormSuccess').show();
                    $(`${modalId} .title`).hide();
                    $('#multiStepForm .submit-btn').hide();
                    $('#multiStepForm .prev-btn').hide();
                    $(getStepPointSelector()).text('✓');
                    $('#multiStepForm').removeClass('w-60');
                    $(`${modalId} .modal-body`).addClass('background-none');
                    currentStep++;
                    $(getStepPointSelector()).addClass('active').removeClass('inactive').text('✓');

                },
                error: (xhr, status, error) => {
                }
            });
        } else {
            $(getStepSelector()).hide();
            $('#multiStepFormSuccess').show();
            $(`${modalId} .title`).hide();
            $('#multiStepForm .submit-btn').hide();
            $('#multiStepForm .prev-btn').hide();
            $(getStepPointSelector()).text('✓');
            $('#multiStepForm').removeClass('w-60');
            $(`${modalId} .modal-body`).addClass('background-none');
            currentStep++;
            $(getStepPointSelector()).addClass('active').removeClass('inactive').text('✓');
        }

    }

    $('#multiStepForm .next-btn').click(handleNextButtonClick);
    $('#multiStepForm .prev-btn').click(handlePrevButtonClick);
    $("#multiStepForm").submit(handleFormSubmit);
}

export {submitMailCta, multiStepForm, getCookie};